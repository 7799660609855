import gql from 'graphql-tag';

const configuracionesTableQuery = gql`
    query configuraciones($whereConditions:ConfiguracionesWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        configuraciones(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                nombre,
                clave,
                valor,
                descripcion,
                cliente_id,
                empresa_id,
                sucursal_id,
                registro_patronal_id,
                cliente{
                    nombre
                },
                empresa{
                    nombre
                },
                sucursal{
                    nombre
                }
                registroPatronal{
                    nombre,
                    clave
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { configuracionesTableQuery };

export default queries;
