<template>
    <div id="pageTable"> 
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Configuración'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="rol == 'root'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Registro patronal"
                                    :items="registrosPatronales"
                                    item-text="nombreClave"
                                    item-value="id"
                                    persistent-hint
                                    v-model="registro_value"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div v-if="item.cliente === null">
                                        General
                                    </div>
                                    <div v-else>
                                        {{ item.cliente.nombre }}
                                    </div>
                                    <div class="tblSecundario" v-if="item.empresa === null && item.registroPatronal === null">
                                        Incidencia General
                                    </div>
                                    <div class="tblSecundario" v-else-if="item.empresa === null && item.registroPatronal != null">
                                        {{ item.registroPatronal.nombre }} - {{item.registroPatronal.clave}}
                                    </div>
                                    <div v-else class="tblSecundario">
                                        {{ item.empresa.nombre }}
                                    </div>
                                </td>
                                <td><div>{{item.nombre}}</div></td>
                                <td><div>{{item.descripcion}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="700px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background:#FFF">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Nombre" 
                                                        class="pa-0 ma-0" 
                                                        v-model="configuracion.nombre" 
                                                        required
                                                        disabled
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Descripción"
                                                        v-model="configuracion.descripcion"
                                                        required
                                                        :error-messages="errors"
                                                    ></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                                <template v-if="rol == 'root'">
                                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Cliente"
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            disabled
                                                            class="pa-0 ma-0"
                                                        >
                                                        </v-autocomplete>
                                                    </v-col>
                                                </template>
                                                <v-col cols="12" xs="12" sm="6" md="6"  
                                                    v-if="rol != 'admin-empresa' && 
                                                        clave != 'credenciales_timbrado' && 
                                                        clave != 'factor_calculo' && 
                                                        clave != 'version_de_facturacion' 
                                                        " class="py-0">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empresa"
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        class="pa-0 ma-0"
                                                        disabled
                                                        required 
                                                    >
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="6" md="6"  v-if="clave == 'credenciales_timbrado' || clave == 'version_de_facturacion'" class="py-0">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Registro patronal"
                                                        :items="registrosPatronales"
                                                        item-text="nombreClave"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="registro_value"
                                                        class="pa-0 ma-0"
                                                        disabled
                                                        required 
                                                    >
                                                    </v-autocomplete>
                                                </v-col>
                                            </template>
                                            <!-- Configuracion de texto de correo incidencias -->
                                            <v-col xs="12" sm="12" md="12" class="my-0">
                                                <template v-if="clave === 'correo_incidencia'">
                                                    <ValidationProvider  v-slot="{ errors }" name="Mensaje Email" rules="required">
                                                        <v-textarea
                                                            outlined
                                                            class="estiloLetras"
                                                            v-model="configuracion.valor"
                                                            label="Mensaje Email"
                                                            rows="2"
                                                            :error-messages="errors"
                                                            required
                                                        ></v-textarea>
                                                    </ValidationProvider>
                                                </template>
                                                <!-- Configuracion por faltas -->
                                                <template v-else-if="clave === 'notificacion_faltas'">
                                                    <ValidationProvider  v-slot="{ errors }" name="Mensaje Email" rules="required">
                                                        <v-text-field
                                                            outlined
                                                            v-model="configuracion.valor"
                                                            class="estiloLetras py-0 my-0"
                                                            label="Notificación por numero de faltas"
                                                            type="number"
                                                            required
                                                            :error-messages="errors"
                                                        ></v-text-field>
                                                    </ValidationProvider>
                                                </template>
                                                <!-- Configuracion de vacaciones -->
                                                <v-container
                                                    v-else-if="clave === 'vacaciones_acomulables'"
                                                    class="sexo"
                                                    fluid
                                                    grid-list-xl
                                                >
                                                    <v-row justify-center>
                                                        <v-col cols="12" xs="12" sm="5" md="5" class="pt-0 pb-0 ma-0">
                                                            <template>
                                                                <v-container class="ma-0 pa-0" fluid>
                                                                    <h4 class="tituloRadio">
                                                                        Vacaciones Acumulables
                                                                    </h4>
                                                                    <v-radio-group
                                                                        class="ma-0 pa-0"
                                                                        v-model="configuracion.valor"
                                                                        row
                                                                    >
                                                                        <v-radio label="Si" value="S"></v-radio>
                                                                        <v-radio label="No" value="N"></v-radio>
                                                                    </v-radio-group>
                                                                </v-container>
                                                            </template>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>


                                                <!-- Configuracion de ranking -->
                                                <v-container v-else-if="clave == 'empleado_ranking'" 
                                                    class="sexo"
                                                    fluid
                                                    grid-list-xl
                                                >
                                                    <v-row justify-center>
                                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                            <h4 class="tituloRadio">
                                                                Configuracion del ranking
                                                            </h4>

                                                            <template v-for="(value, index) in ranking">
                                                                <ValidationProvider  v-slot="{ errors }" :key="index" :name="value.name" rules="required|numeros_positivos">
                                                                    <v-text-field
                                                                        :key="index"
                                                                        v-model="value.value"
                                                                        outlined
                                                                        class="estiloLetras py-0 my-0"
                                                                        :label="value.name"
                                                                        type="number"
                                                                        :error-messages="errors"
                                                                    ></v-text-field>
                                                                </ValidationProvider>
                                                            </template>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>

                                                <!-- Configuracion de Certificados SAT -->
                                                <template v-else-if="clave === 'credenciales_timbrado'" class="sexo" fluid grid-list-xl>
                                                    <v-divider class="mb-9"></v-divider>
                                                    <v-row >
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                                                            
                                                            <div class="RangoIngreso">
                                                                <span>Credenciales para timbrado de facturas ante el SAT</span>
                                                            </div>
                                                        </v-col>

                                                        <v-col cols="12" xs="10" sm="10" md="8" lg="8" class="py-0">
                                                            <v-file-input
                                                                outlined
                                                                label="Seleccionar archivo .cer"
                                                                v-model="archivoCER"
                                                                accept=".cer"
                                                                prepend-icon=""
                                                                append-icon="attach_file"
                                                                id="fileCer"
                                                                @click:append="open('fileCer')"
                                                            >
                                                            </v-file-input>
                                                        </v-col>
                                                        <v-col v-if="btnCER" cols="12" xs="2" sm="2" md="2" lg="2" class="py-2">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" @click="descargar('cer')" :loading="isLoadingCER" :disabled="isLoadingKEY" color="#1E2245" elevation="0" dark fab small>
                                                                        <v-icon>arrow_downward</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Descargar archivo</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                        <v-col cols="12" xs="10" sm="10" md="8" lg="8" class="py-0">
                                                            <v-file-input
                                                                outlined
                                                                label="Seleccionar archivo .key"
                                                                v-model="archivoKEY"
                                                                accept=".key"
                                                                prepend-icon=""
                                                                append-icon="attach_file"
                                                                id="fileKey"
                                                                @click:append="open('fileKey')"
                                                            >
                                                            </v-file-input>
                                                        </v-col>
                                                        <v-col v-if="btnKEY" cols="12" xs="2" sm="2" md="2" lg="2" class="py-2">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" @click="descargar('key')" :loading="isLoadingKEY" :disabled="isLoadingCER" color="#1E2245" elevation="0" dark fab small>
                                                                        <v-icon>arrow_downward</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Descargar archivo</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                        <v-col cols="12" xs="10" sm="10" md="8" lg="8" class="py-0">
                                                            <v-text-field 
                                                                outlined
                                                                label="Contraseña"
                                                                prepend-inner-icon="lock" 
                                                                name="Contraseña" 
                                                                id="password" 
                                                                type="password" 
                                                                v-model="pass" 
                                                                placeholder="**********">
                                                                <template slot="prepend-inner">
                                                                    <i class="material-icons-outlined my-0 color-color">lock</i>
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-col>
                                            <template v-if="clave == 'factor_calculo'">
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0" >
                                                    <v-text-field
                                                        outlined
                                                        readonly
                                                        v-model="registroPatronal.nombre"
                                                        label="Registro patronal"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                    <v-container class="ma-0 pa-0" fluid>
                                                        <h4 class="tituloRadio">Factor de cálculo</h4>
                                                        <v-radio-group class="ma-0 pa-0" v-model="configuracion.valor" row>
                                                            <v-radio label="30" value="30"></v-radio>
                                                            <v-radio label="30.40" value="30.40"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>
                                                </v-col>
                                            </template>
                                            <template v-if="clave == 'version_de_facturacion'">
                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                    <v-container class="ma-0 pa-0" fluid>
                                                        <h4 class="tituloRadio">Versión de facturación</h4>
                                                        <v-radio-group class="ma-0 pa-0" v-model="configuracion.valor" row>
                                                            <v-radio label="3.3" value="3.3"></v-radio>
                                                            <v-radio label="4.0" value="4.0"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>
                                                </v-col>
                                            </template>

                                            <template v-if="clave == 'reporte_nomina'">
                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                    <v-container class="ma-0 pa-0" fluid>
                                                        <h4 class="tituloRadio">Tipo reporte de la Nómina calculada</h4>
                                                        <v-radio-group class="ma-0 pa-0" v-model="configuracion.valor" row>
                                                            <v-radio label="General" value="general"></v-radio>
                                                            <v-radio label="Desglosado" value="desglosado"></v-radio>
                                                        </v-radio-group>
                                                    </v-container>
                                                </v-col>
                                            </template>

                                            <!--Configuracion notificaciones-->
                                            <template v-if="clave == 'notificaciones'">
                                                <div 
                                                    v-for=" item in arrNotificaciones"
                                                    :key="item.clave"
                                                >
                                                    <h4 class="tituloRadio mb-2">
                                                        {{ item.nombre }}
                                                    </h4>
                                                    <v-row class="py-0 ma-0">
                                                         <!--USUARIOS FALTAS-->
                                                         <v-col 
                                                            cols="5" 
                                                            sm="5" 
                                                            md="5" 
                                                            class="py-0 pl-0"
                                                            v-if="item.clave == 'faltas'"
                                                            >
                                                            <multiple-autocomplete
                                                            outlined
                                                            :items="usuarios"
                                                            item_text="nombre"
                                                            @dataMultiple="(data)=> usuariosFaltas = data"
                                                            :valorDefecto="usuariosFaltas"
                                                            label="Usuarios"
                                                            ref="multUsuariosFaltas"
                                                            />
                                                        </v-col>
                                                        <!--USUARIOS CONTRATOS-->
                                                        <v-col 
                                                            cols="5" 
                                                            sm="5" 
                                                            md="5" 
                                                            class="py-0 pl-0"
                                                            v-if="item.clave == 'contratos'"
                                                            >
                                                            <multiple-autocomplete
                                                            outlined
                                                            :items="usuarios"
                                                            item_text="nombre"
                                                            @dataMultiple="(data)=> usuariosContratos = data"
                                                            :valorDefecto="usuariosContratos"
                                                            label="Usuarios"
                                                            ref="multUsuariosContratos"
                                                            />
                                                        </v-col>
                                                        <!--USUARIOS TARJETAS SALUD-->
                                                        <v-col 
                                                            cols="5" 
                                                            sm="5" 
                                                            md="5" 
                                                            class="py-0 pl-0"
                                                            v-if="item.clave == 'tarjetas'"
                                                            >
                                                            <multiple-autocomplete
                                                            outlined
                                                            :items="usuarios"
                                                            item_text="nombre"
                                                            @dataMultiple="(data)=> usuariosTarjetas = data"
                                                            :valorDefecto="usuariosTarjetas"
                                                            label="Usuarios"
                                                            ref="multUsuariosTarjetas"
                                                            />
                                                        </v-col>
                                                        <v-col 
                                                            :cols="item.clave == 'cumpleanios' ? '8' : '5'" 
                                                            :sm="item.clave == 'cumpleanios' ? '8' : '5'"
                                                            :md="item.clave == 'cumpleanios' ? '8' : '5'" 
                                                            class="py-0 pr-0"
                                                            :class="item.clave == 'cumpleanios' ? 'pl-0': ''"
                                                            v-if="item.clave == 'faltas' || item.clave == 'contratos' || item.clave == 'tarjetas' || item.clave == 'cumpleanios'"
                                                            >
                                                            <ValidationProvider  v-slot="{ errors }"  name="Dias" rules="numeros_positivos" class="d-flex pa-0 ma-0">
                                                                <v-text-field
                                                                    outlined
                                                                    v-model="item.dias"
                                                                    class="estiloLetras py-0 my-0"
                                                                    type="number"
                                                                    label="Días"
                                                                    :error-messages="errors"
                                                                   
                                                                ></v-text-field>
                                                                <v-tooltip top class="pb-7" v-if="item.clave == 'contratos'">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon
                                                                        color="#96999A"
                                                                        v-on="on"
                                                                        slot="activator"
                                                                        class="pb-7 pl-2 outlined_v_icon"
                                                                        >info</v-icon
                                                                        >
                                                                    </template>
                                                                    <span class="pb-7 textTooltip">
                                                                        Días de anticipación para la notificación<br>
                                                                        del vencimiento de contrato.
                                                                    </span>
                                                                </v-tooltip>
                                                            </ValidationProvider>
                                                        </v-col>
                                                        <v-col 
                                                            cols="2" 
                                                            class="py-0"
                                                            v-if="item.clave == 'faltas' || item.clave == 'contratos' || item.clave == 'tarjetas' || item.clave == 'cumpleanios'"
                                                            >
                                                            <v-checkbox
                                                                label="Activo"
                                                                class="mb-0 mx-0 mt-3"
                                                                v-model="item.activo"
                                                            ></v-checkbox>   
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </template>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                       @click="cerrarModal()" 
                                       :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Configuraciones';
import Notify from '@/plugins/notify';
import configuracionesApi from "@/api/configuraciones";
import clienteAPi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import apiRegistrosPatronales from "@/api/nominas/registroPatronal"
import usuariosApi from '@/api/usuarios.js'
import VuetifyMultipleAutocomplete2 from './../components/VuetifyMultipleAutocomplete2.vue'


export default {
    components: {
        'data-table': Datatable,
        'multiple-autocomplete': VuetifyMultipleAutocomplete2
    },
    data() {
        return {
            columns         : [
                {
                    label: "Empresa",
                    name: "empresa",
                    filterable: false,
                },
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: "Descripción",
                    name: "descripcion",
                    filterable: false,
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            configuracion   : {
                id          : 0,
                nombre      : null,
                descripcion : null,
                valor       : null, 
                empresa_id  : null,
                cliente_id  : null,
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imagen          : '',
            rol             : null,
            datosLogin      : null,
            clientes        : [],
            empresas        : [],
            usuarios        : [],
            cliente_value   : null,
            empresa_value   : null,
            clave           : null,
            nombre          : null,
            isLoadingModal  : false,
            ranking         : [],
            archivoCER      : null,
            archivoKEY      : null,
            pass            : null,
            timbrado        : {
                numero_cetificado : null,
                password        : null,
                url_cer         : null,
                url_key         : null,
                url_pem         : null,
            },
            isLoadingDownload   : false,
            registro_value      : null,
            registrosPatronales : [],
            dialogDownload      : false,
            btnCER              : false,
            btnKEY              : false,
            isLoadingKEY        : false,
            isLoadingCER        : false,
            registroPatronal    : null,
            arrNotificaciones       : [],
            usuariosFaltas: [],
            usuariosContratos: [],
            usuariosTarjetas: [],

        }
    },
    watch: {
        cliente_value: function(val){
            if( val != null){
                this.cambiaCliente(val);
                if(this.clave === "credenciales_timbrado" || this.clave == 'version_de_facturacion' || this.clave == 'busqueda_general'){
                    this.getRegistrosPatronales(val);
                }
            }
        },
    },
    methods: {
        descargar(tipoArchivo){
            let self = this;
            this.isLoadingDownload = true;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let urlDescarga = "incidencias/download";
            let urlArchivo = null;
            let urlArchivoSplit = null;
            let nombreArchivo = null;

            if(tipoArchivo === "cer"){
                urlArchivo = this.timbrado.url_cer;
                urlArchivoSplit = this.timbrado.url_cer.split("/");
                nombreArchivo = urlArchivoSplit[3];
                this.isLoadingCER   = true;
            }
            if(tipoArchivo === "key"){
                urlArchivo = this.timbrado.url_key;
                urlArchivoSplit = this.timbrado.url_key.split("/");
                nombreArchivo = urlArchivoSplit[3];
                this.isLoadingKEY = true;
                
            }
            
            let parametros = { url_archivo: urlArchivo };
            try {
                axios({
                method: "post",
                url: urlDescarga,
                responseType: "blob",
                data: parametros,
                }).then(function(response) {
                    FileDownload(response.data, nombreArchivo);
                    self.isLoadingCER = false;
                    self.isLoadingKEY = false;
                });
            } catch (error) {
                console.log(error);
                Notify.ErrorToast("Ocurrió un error al descargar el archivo.")
                self.isLoadingCER = false;
                self.isLoadingKEY = false;
            }
        },
        open(id){
            //this.$refs.fileDocument.$el.querySelector('input').click()
            //this.$refs.fileDocument.click()
            document.getElementById(id).click();
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.imagen     = "/static/modal/configuracionGeneral.svg";
            this.isLoadingModal  = true;

            if(accion == "add"){
                this.tituloModal = "Configuración general.";
                
            } else {
                this.tituloModal = "Configurar información general";
                this.configuracion.id           = data.id;
                this.configuracion.nombre       = data.nombre;
                this.configuracion.descripcion  = data.descripcion;
                this.configuracion.valor        = data.valor;
                this.configuracion.cliente_id   = data.cliente_id;
                this.cliente_value              = data.cliente_id;
                this.configuracion.empresa_id   = data.empresa_id;
                this.empresa_value              = data.empresa_id;
                this.registro_value             = data.registro_patronal_id;
                this.clave                      = data.clave;

                if(data.clave == "factor_calculo"){
                    this.registroPatronal = data.registroPatronal;
                }
                if(data.clave == "empleado_ranking"){
                    this.ranking = JSON.parse(data.valor);
                }
                if(data.clave === "credenciales_timbrado"){
                    this.timbrado = JSON.parse(data.valor);
                    if(this.timbrado.url_cer != '' && this.timbrado.url_cer != null){
                        this.btnCER = true;
                    }
                    else{
                        this.btnCER = false;
                    }
                    if(this.timbrado.url_key != '' && this.timbrado.url_key != null){
                        this.btnKEY = true;
                    }
                    else{
                        this.btnKEY = false;
                    }
                }
                if(data.clave == 'notificaciones'){
                    this.getUsuarios()
                    this.arrNotificaciones = JSON.parse(data.valor)
                    this.usuariosFaltas = this.arrNotificaciones[0].usuarios_id
                    this.usuariosContratos = this.arrNotificaciones[1].usuarios_id
                    this.usuariosTarjetas = this.arrNotificaciones[2].usuarios_id
                    //console.log(this.usuariosSeleccionados);
                    //console.log(this.arrNotificaciones);
                }
            }
            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id, this.empresa_id);
            } else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        async resetValues() {
            this.configuracion  = {
                id          : 0,
                nombre      : null,
                descripcion : null,
                valor       : null,
                empresa_id  : null,
                cliente_id  : null,
            };
            this.cliente_value  = null;
            this.empresa_value  = null;
            this.registro_value = null;
            this.archivoCER     = null;
            this.archivoKEY     = null;
            this.pass           = null;
            this.empresas       = [];
            this.nombre         = null;
            this.usuarios       = [];
            this.usuariosFaltas = []
            this.usuariosContratos = []
            this.usuariosTarjetas = []
            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
            });
        },
        async loadModalData() {
            await this.resetValues();         
            this.$apollo.queries.configuraciones.skip = true;

            if(this.rol == 'root'){
                this.getClientes();
                this.clave = "busqueda_general" // linea para que pueda pasar la validación para realizar peticion a Registros patronales
            }
            else if(this.rol == 'admin'){
                this.clave = "busqueda_general" // linea para que pueda pasar la validación para realizar peticion a Registros patronales
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            
        },
        setFilters() {
            this.filters = {AND:[]};
            let nombre = this.nombre;
            let cliente = this.cliente_value;;
            let empresa = this.empresa_value;
            let sucursal = this.sucursal_value_search;
            let rp = this.registro_value;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } 
            else if (this.rol == "admin-empresa") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            } 
            else if (this.rol == "admin-sucursal") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
            } 

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }
            
            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(rp != null && rp != undefined && rp != ""){
                this.filters.AND.push({column:'REGISTRO_PATRONAL_ID', value:rp});
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
            }
            if(sucursal != null && sucursal != undefined && sucursal != ""){
                this.filters.AND.push({column:'SUCURSAL_ID', value:sucursal});
            }

            this.paginationData.numberPage = 1;
            this.$apollo.queries.configuraciones.skip = false;
            this.resetValues();
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estásseguro que deseas eliminar la configuración " + data.nombre + "?",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    configuracionesApi.deleteConfiguracion(data.id).then(response => {
                        Notify.Success("Operación exitosa", "La configuración ha sido eliminada satisfactoriamente");
                        this.$apollo.queries.configuraciones.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                this.configuracion.cliente_id = this.cliente_value;
                this.configuracion.empresa_id = this.empresa_value;

                if (this.clave == "empleado_ranking") {
                    let valor = JSON.stringify(this.ranking);
                    this.configuracion.valor = valor;
                }

                if(this.clave == "notificaciones"){
                    this.arrNotificaciones[0].usuarios_id = this.usuariosFaltas
                    this.arrNotificaciones[1].usuarios_id = this.usuariosContratos
                    this.arrNotificaciones[2].usuarios_id = this.usuariosTarjetas
                    let valor = JSON.stringify(this.arrNotificaciones)
                    this.configuracion.valor = valor
                    //console.log(this.configuracion);
                }


                if(this.accion === "add") {
                    configuracionesApi.addConfiguracion(this.configuracion)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "Se creó la configuración satisfactoriamente.");
                        this.$apollo.queries.configuraciones.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    if(this.clave === "credenciales_timbrado"){
                        let formData = new FormData();
                        if(this.cliente_value != null){
                            formData.append('cliente_id', this.cliente_value);
                        }
                        if(this.registro_value != null){
                            formData.append('registro_patronal_id', this.registro_value);
                        }
                        if(this.archivoCER != null){
                            formData.append('cer', this.archivoCER);
                        }
                        if(this.archivoKEY != null){
                            formData.append('key', this.archivoKEY);
                        }
                        if(this.pass != null){
                            formData.append('password', this.pass);
                        }

                        configuracionesApi.updateTimbre(formData)
                        .then((response) => {
                            Notify.Success("Operación exitosa", "Se actualizó la configuración satisfactoriamente.");
                            this.$apollo.queries.configuraciones.refetch();
                            this.cerrarModal();
                            this.$nextTick(() => {
                                this.$refs.form.reset();
                            });
                        })
                        .catch(err => {
                            this.isLoading = false;
                            this.isSaving = false;
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }

                                Notify.ErrorToast(leyenda);
                            }
                        });
                    }
                    else{
                        configuracionesApi.updateConfiguracion(this.configuracion)
                        .then((response) => {
                            Notify.Success("Operación exitosa", "Se actualizó la configuración satisfactoriamente.");
                            this.$apollo.queries.configuraciones.refetch();
                            this.cerrarModal();
                            this.$nextTick(() => {
                                this.$refs.form.reset();
                            });
                        })
                        .catch(err => {
                            this.isLoading = false;
                            this.isSaving = false;
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }

                                Notify.ErrorToast(leyenda);
                            }
                        });
                    }                    
                }
            });
        },
        getClientes() {
            let parametros = { activo: true, paginate: false };
            clienteAPi.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        cambiaCliente(id, empresa = null) {
            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
                if (this.accion != "add") {
                    this.configuracion.empresa_id = this.empresa_id;
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        getRegistrosPatronales(id) {
            this.registrosPatronales = [];
            let parametros = { activo: true, paginate: false, cliente_id: id };
            apiRegistrosPatronales.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.registrosPatronales.push(item);
                    })
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registro patronales.");
            });
        },
        getUsuarios(){
            let parametros = {
                activo: true,
                paginate: false,
                cliente_id: this.cliente_value,
                administradores: true
            }

            usuariosApi.getUsuarios(parametros).then(resp => {
                this.usuarios = resp.data
                console.log(this.usuarios);
            }).catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los usuarios.");
            })
        },
        cargaInicial(){
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            if(this.rol == "root"){
                this.getClientes();
            }
 
            if (this.rol == "admin") {
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
            }

            if (this.rol == "admin-empresa") {
                this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
            }

            if(this.rol == "admin-sucursal"){
                this.filters.AND.push({column:'SUCURSAL_ID', value:this.datosLogin.sucursal_id});
            }
        },
        redireccionar(){
            this.$router.push('/dashboard');
        }
    },
    created() {
        this.cargaInicial();
    },
    mounted(){
        this.$apollo.queries.configuraciones.skip = false;
    },
    apollo: {
        configuraciones: {
            query       : queries.configuracionesTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions: this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.configuraciones;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .tituloRadio{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #A0A4A8;
        padding-bottom: 10px;
    }
    
</style>
